import {
  CREATE_AGENCY,
  CREATE_AGENCY_SUCCESS,
  CREATE_AGENCY_FAILED,
  CREATE_AGENCY_RESET,
} from "./types";

const initialState = {
  create: {
    isLoading: false,
    isCreated: false,
    data: [],
    error: null,
  },
};

const agency = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_AGENCY:
      return {
        ...state,
        create: {
          ...state.create,
          isLoading: true,
          error: null,
          isCreated: false,
        },
      };

    case CREATE_AGENCY_SUCCESS:
      return {
        ...state,
        create: {
          ...state.create,
          isLoading: false,
          isCreated: true,
        },
      };

    case CREATE_AGENCY_FAILED:
      return {
        ...state,
        create: {
          ...state.create,
          isLoading: false,
          error: action.payload,
        },
      };

    case CREATE_AGENCY_RESET:
      return { ...state, create: { ...initialState.create } };

    default:
      return state;
  }
};

export default agency;
