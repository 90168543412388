import {
  GET_INVOICE_LIST,
  GET_INVOICE_SUCCESS,
  GET_INVOICE_FAILED ,
  SET_UPLOAD_INVOICE,
  SET_UPLOAD_INVOICE_SUCCESS,
  SET_UPLOAD_INVOICE_FAILED,

  GET_USERPLAN,
  GET_USERPLAN_SUCCESS,
  GET_USERPLAN_FAILED,

  SET_APPROVE_PLAN,
  SET_APPROVE_PLAN_SUCCESS,
  SET_APPROVE_PLAN_FAILED,
  SET_APPROVE_PLAN_RESET,

  SET_APPROVE_REJECT_PLAN,
  SET_APPROVE_REJECT_PLAN_SUCCESS,
  SET_APPROVE_REJECT_PLAN_FAILED,

  SET_ADVANCE_PLAN,
  SET_ADVANCE_PLAN_SUCCESS,
  SET_ADVANCE_PLAN_FAILED,
  SET_ADVANCE_PLAN_RESET,

  GET_ADVANCE_PLAN,
  GET_ADVANCE_PLAN_SUCCESS,
  GET_ADVANCE_PLAN_FAILED,

  APPROVE_INVOICE,
  APPROVE_INVOICE_SUCCESS,
  APPROVE_INVOICE_FAILED,
  APPROVE_INVOICE_RESET,

  REJECT_INVOICE,
  REJECT_INVOICE_SUCCESS,
  REJECT_INVOICE_FAILED,
  REJECT_INVOICE_RESET,

  GET_INVOICE_LOGS,
  GET_INVOICE_LOGS_SUCCESS,
  GET_INVOICE_LOGS_FAILED,
} from "./types";

const initialState = {
  invoice: {
    isLoading: false,
    isCreated: false,
    data: [],
    error: null,
  }, 
  upload: {
    isLoading: false,
    isCreated: false,
    data: [],
    error: null,
  },

  userplan: {
    isLoading: false,
    isCreated: false,
    data: [],
    error: null,
  },

  approve: {
    isLoading: false,
    isCreated: false,
    data: [],
    error: null,
  },

  planstatus: {
    isLoading: false,
    isCreated: false,
    data: [],
    error: null,
  },

  advanceplan: {
    isLoading: false,
    isCreated: false,
    data: [],
    error: null,
  },

  advanceplanlist: {
    isLoading: false,
    isCreated: false,
    data: [],
    error: null,
  },

  approvedInvoice: {
    isLoading: false,
    isCreated: false,
    data: [],
    error: null,
  },

  rejectedInvoice: {
    isLoading: false,
    isCreated: false,
    data: [],
    error: null,
  },

  invoiceLogsdata: {
    isLoading: false,
    isCreated: false,
    data: [],
    error: null,
  },
};

const invoicelist = (state = initialState, action) => {
  switch (action.type) {
    case GET_INVOICE_LIST:
      return {
        ...state,
        invoice: {
          ...state.create,
          isLoading: true,
          error: null,
        },
    };
    case GET_INVOICE_SUCCESS:
      return {
        ...state,
        invoice: {
          data: action.payload,
        },
      };
    case GET_INVOICE_FAILED:
      return {
        ...state,
        invoice: {
          ...state.create,
          isLoading: false,
          error: action.payload,
        },
      };

    case SET_UPLOAD_INVOICE:
      return {
        ...state,
        upload: {
          ...state.create,
          isLoading: true,
          isCreated: false,
          error: null,
        },
      };
    case SET_UPLOAD_INVOICE_SUCCESS:
      return {
        ...state,
        upload: {
          ...state.create,
          isLoading: false,
          isCreated: true,
          data: action.payload,
          error: null
        },
      };
    case SET_UPLOAD_INVOICE_FAILED:
      return {
        ...state,
        upload: {
          ...state.create,
          isLoading: false,
          isCreated: false,
          error: action.payload,
        },
      };
    
    case GET_USERPLAN:
      return {
        ...state,
        userplan: {
          ...state.create,
          isLoading: true,
          error: null,
        },
    };
    case GET_USERPLAN_SUCCESS:
      return {
        ...state,
        userplan: {
          data: action.payload,
        },
      };
    case GET_USERPLAN_FAILED:
      return {
        ...state,
        userplan: {
          ...state.create,
          isLoading: false,
          error: action.payload,
        },
      };

    case SET_APPROVE_PLAN:
      return {
        ...state,
        approve: {
          ...state.create,
          isLoading: true,
          error: null,
          isCreated: null
        },
    };
    case SET_APPROVE_PLAN_SUCCESS:
      return {
        ...state,
        approve: {
          data: action.payload,
          error: false,
          isCreated: true,
        },
      }; 
    case SET_APPROVE_PLAN_FAILED:
      return {
        ...state,
        approve: {
          ...state.create,
          isLoading: false,
          isCreated: false,
          error: action.payload,
        },
      };
    case SET_APPROVE_PLAN_RESET: 
      return {
        ...state.planstatus,
        
      }; 
  
    case SET_APPROVE_REJECT_PLAN:
      return {
        ...state,
        planstatus: {
          ...state.planstatus,
          isLoading: true,
          error: null,
          isCreated: null
        },
    };
    case SET_APPROVE_REJECT_PLAN_SUCCESS:
      return {
        ...state,
        planstatus: {
          data: action.payload,
          error: false,
          isCreated: true,
        },
      }; 
    case SET_APPROVE_REJECT_PLAN_FAILED:
      return {
        ...state,
        planstatus: {
          ...state.planstatus,
          isLoading: false,
          isCreated: false,
          error: action.payload,
        },
      };
      case SET_ADVANCE_PLAN:
        return {
          ...state,
          advanceplan: {
            ...state.advanceplan,
            isLoading: true,
            error: null,
            isCreated: null
          },
      };
      case SET_ADVANCE_PLAN_SUCCESS:
        return {
          ...state,
          advanceplan: {
            ...state.advanceplan,
            data: action.payload,
            error: false,
            isCreated: true,
          },
        }; 
      case SET_ADVANCE_PLAN_FAILED:
        return {
          ...state,
          advanceplan: {
            ...state.advanceplan,
            isLoading: false,
            isCreated: false,
            error: action.payload,
          },
        };
      case SET_ADVANCE_PLAN_RESET: 
        return {
          ...state.advanceplan,
        }; 

      case GET_ADVANCE_PLAN:
        return {
          ...state,
          advanceplanlist: {
            ...state.advanceplanlist,
            isLoading: true,
            error: null,
            isCreated: null
          },
      };
      case GET_ADVANCE_PLAN_SUCCESS:
        return {
          ...state,
          advanceplanlist: {
            ...state.advanceplanlist,
            data: action.payload,
            error: false,
            isCreated: true,
          },
        }; 
      case GET_ADVANCE_PLAN_FAILED:
        return {
          ...state,
          advanceplanlist: {
            ...state.advanceplanlist,
            isLoading: false,
            isCreated: false,
            error: action.payload,
          },
        };
      
        case APPROVE_INVOICE:
          return {
            ...state,
            approvedInvoice: {
              ...state.approvedInvoice,
              isLoading: true,
              error: null,
              isCreated: null
            },
        };
        case APPROVE_INVOICE_SUCCESS:
          return {
            ...state,
            approvedInvoice: {
              data: action.payload,
              error: false,
              isCreated: true,
            },
          }; 
        case APPROVE_INVOICE_FAILED:
          return {
            ...state,
            approvedInvoice: {
              ...state.approvedInvoice,
              isLoading: false,
              isCreated: false,
              error: action.payload,
            },
          };
        case APPROVE_INVOICE_RESET: 
          return {
            ...state.approvedInvoice,
            
          }; 

          case REJECT_INVOICE:
            return {
              ...state,
              rejectedInvoice: {
                ...state.rejectedInvoice,
                isLoading: true,
                error: null,
                isCreated: null
              },
          };
          case REJECT_INVOICE_SUCCESS:
            return {
              ...state,
              rejectedInvoice: {
                data: action.payload,
                error: false,
                isCreated: true,
              },
            }; 
          case REJECT_INVOICE_FAILED:
            return {
              ...state,
              rejectedInvoice: {
                ...state.rejectedInvoice,
                isLoading: false,
                isCreated: false,
                error: action.payload,
              },
            };
          case REJECT_INVOICE_RESET: 
            return {
              ...state.rejectedInvoice,
              
            }; 
      
            case GET_INVOICE_LOGS:
              return {
                ...state,
                invoiceLogsdata: {
                  ...state.invoiceLogsdata,
                  isLoading: true,
                  error: null,
                  isCreated: null
                },
            };
            case GET_INVOICE_LOGS_SUCCESS:
              return {
                ...state,
                invoiceLogsdata: {
                  data: action.payload,
                  error: false,
                  isCreated: true,
                },
              }; 
            case GET_INVOICE_LOGS_FAILED:
              return {
                ...state,
                invoiceLogsdata: {
                  ...state.invoiceLogsdata,
                  isLoading: false,
                  isCreated: false,
                  error: action.payload,
                },
              };
            
        
        default:
      return state;
  }
};

export default invoicelist;
