import { SET_MODAL_STATUS } from "./types";

export const setModalStatus = (value) => {
  return {
    type: SET_MODAL_STATUS,
    payload: value,
  };
};

export const reset = (RESET) => {
  return {
    type: RESET,
  };
};