import {
    GET_AGREEMENTS,
    GET_AGREEMENTS_FAILED,
    GET_AGREEMENTS_RESET,
    GET_AGREEMENTS_SUCCESS,
    GET_CLIENTEXPORT,
    GET_CLIENTEXPORT_FAILED,
    GET_CLIENTEXPORT_SUCCESS,
    GET_CLIENTLIST,
    GET_CLIENTLIST_FAILED,
    GET_CLIENTLIST_SUCCESS, GET_DAILY_SPENDS, GET_DAILY_SPENDS_FAILED, GET_DAILY_SPENDS_RESET, GET_DAILY_SPENDS_SUCCESS,
    GET_MONTH,
    GET_MONTH_FAILED,
    GET_MONTH_SUCCESS,
    GET_SPENDS,
    GET_SPENDS_FAILED,
    GET_SPENDS_RESET,
    GET_SPENDS_SUCCESS,
    GET_BILLING_SPENT,
    GET_BILLING_SPENT_FAILED,
    GET_BILLING_SPENT_SUCCESS,

    UPDATE_SPENT_STATUS,
    UPDATE_SPENT_STATUS_FAILED,
    UPDATE_SPENT_STATUS_SUCCESS,
    UPDATE_SPENT_STATUS_RESET,

    DOWNLOAD_SPENT_STATUS,
    DOWNLOAD_SPENT_STATUS_FAILED,
    DOWNLOAD_SPENT_STATUS_SUCCESS,

    DOWNLOAD_ACTION_STATUS,
    DOWNLOAD_ACTION_STATUS_FAILED,
    DOWNLOAD_ACTION_STATUS_SUCCESS,

} from "./types";

const initialState = {
    get: {
        isLoading: false,
        data: [],
        error: null,
    },
    month: {
        isLoading: false,
        data: [],
        error: null,
    },
    spends: {
        isLoading: false,
        data: [],
        error: null,
    },
    retrieve: {
        isLoading: false,
        data: null,
        error: null,
    },
    clientlist: {
        isLoading: false,
        data: null,
        error: null,
    },
    clientExport: {
        isLoading: false,
        data: null,
        error: null,
    },

    spentrequest: {
        isLoading: false,
        data: null,
        error: null,
    },
    updatespentrequest: {
        isLoading: false,
        data: null,
        error: null,
    },

    downloadSpent: {
        isLoading: false,
        data: null,
        error: null,
    },
    downloadAction: {
        isLoading: false,
        data: null,
        error: null,
    },
};

const agreement = (state = initialState, action) => {
    switch (action.type) {
        case GET_AGREEMENTS:
            return {
                ...state,
                get: {
                    ...state.get,
                    isLoading: true,
                    error: null,
                },
            };
        case GET_AGREEMENTS_SUCCESS:
            return {
                ...state,
                get: {
                    ...state.get,
                    isLoading: false,
                    data: action.payload,
                },
            };
        case GET_AGREEMENTS_FAILED:
            return {
                ...state,
                get: {
                    ...state.get,
                    isLoading: false,
                    error: action.payload,
                },
            };
        case GET_AGREEMENTS_RESET:
            return {...state, get: {...initialState.get}};


        case GET_SPENDS:
            return {
                ...state,
                get: {
                    ...state.get,
                    isLoading: true,
                    error: null,
                },
            };
        case GET_SPENDS_SUCCESS:
            return {
                ...state,
                get: {
                    ...state.get,
                    isLoading: false,
                    data: action.payload,
                },
            };
        case GET_SPENDS_FAILED:
            return {
                ...state,
                get: {
                    ...state.get,
                    isLoading: false,
                    error: action.payload,
                },
            };
        case GET_SPENDS_RESET:
            return {...state, get: {...initialState.get}};

        case GET_DAILY_SPENDS:
            return {
                ...state,
                spends: {
                    ...state.spends,
                    isLoading: true,
                    error: null,
                },
            };
        case GET_DAILY_SPENDS_SUCCESS:
            return {
                ...state,
                spends: {
                    ...state.spends,
                    isLoading: false,
                    data: action.payload,
                },
            };
        case GET_DAILY_SPENDS_FAILED:
            return {
                ...state,
                spends: {
                    ...state.get,
                    isLoading: false,
                    error: action.payload,
                },
            };
        case GET_DAILY_SPENDS_RESET:
            return {...state, spends: {...initialState.spends}};

        case GET_MONTH:
            return {
                ...state,
                month: {
                    ...state.get,
                    isLoading: true,
                    error: null,
                },
            };
        case GET_MONTH_SUCCESS:
            return {
                ...state,
                month: {
                    ...state.get,
                    isLoading: false,
                    data: action.payload,
                },
            };
        case GET_MONTH_FAILED:
            return {
                ...state,
                month: {
                    ...state.get,
                    isLoading: false,
                    error: action.payload,
                },
            };
        case GET_CLIENTLIST:
            return {
                ...state,
                clientlist: {
                    ...state.get,
                    isLoading: true,
                    error: null,
                },
            };
        case GET_CLIENTLIST_SUCCESS:
            return {
                ...state,
                clientlist: {
                    ...state.get,
                    isLoading: false,
                    data: action.payload,
                },
            };
        case GET_CLIENTLIST_FAILED:
            return {
                ...state,
                clientlist: {
                    ...state.get,
                    isLoading: false,
                    error: action.payload,
                },
            };
        case GET_CLIENTEXPORT:
            return {
                ...state,
                clientExport: {
                    ...state.get,
                    isLoading: true,
                    error: null,
                },
            };
        case GET_CLIENTEXPORT_SUCCESS:
            return {
                ...state,
                clientExport: {
                    ...state.get,
                    isLoading: false,
                    data: action.payload,
                },
            };
        case GET_CLIENTEXPORT_FAILED:
            return {
                ...state,
                clientExport: {
                    ...state.get,
                    isLoading: false,
                    error: action.payload,
                },
            };       
        case GET_BILLING_SPENT:
            return {
                ...state,
                spentrequest: {
                    ...state.spentrequest,
                    isLoading: true,
                    error: null,
                },
            };
        case GET_BILLING_SPENT_SUCCESS:
            return {
                ...state,
                spentrequest: {
                    ...state.spentrequest,
                    isLoading: false,
                    data: action.payload,
                },
            };
        case GET_BILLING_SPENT_FAILED:
            return {
                ...state,
                spentrequest: {
                    ...state.spentrequest,
                    isLoading: false,
                    error: action.payload,
                },
            };
        case UPDATE_SPENT_STATUS:
            return {
                ...state,
                updatespentrequest: {
                    ...state.updatespentrequest,
                    isLoading: true,
                    error: null,
                },
            };
        case UPDATE_SPENT_STATUS_SUCCESS:
            return {
                ...state,
                updatespentrequest: {
                    ...state.updatespentrequest,
                    isLoading: false,
                    data: action.payload,
                },
            };
        case UPDATE_SPENT_STATUS_FAILED:
            return {
                ...state,
                updatespentrequest: {
                    ...state.updatespentrequest,
                    isLoading: false,
                    error: action.payload,
                },
            };
        case UPDATE_SPENT_STATUS_RESET:
            return { ...state, updatespentrequest: { ...initialState.updatespentrequest } };

        case DOWNLOAD_SPENT_STATUS:
            return {
                ...state,
                downloadSpent: {
                    ...state.downloadSpent,
                    isLoading: true,
                    error: null,
                },
            };
        case DOWNLOAD_SPENT_STATUS_SUCCESS:
            return {
                ...state,
                downloadSpent: {
                    ...state.downloadSpent,
                    isLoading: false,
                    data: action.payload,
                },
            };
        case DOWNLOAD_SPENT_STATUS_FAILED:
            return {
                ...state,
                downloadSpent: {
                    ...state.downloadSpent,
                    isLoading: false,
                    error: action.payload,
                },
            };
        
        case DOWNLOAD_ACTION_STATUS:
            return {
                ...state,
                downloadAction: {
                    ...state.downloadAction,
                    isLoading: true,
                    error: null,
                },
            };
        case DOWNLOAD_ACTION_STATUS_SUCCESS:
            return {
                ...state,
                downloadAction: {
                    ...state.downloadAction,
                    isLoading: false,
                    data: action.payload,
                },
            };
        case DOWNLOAD_ACTION_STATUS_FAILED:
            return {
                ...state,
                downloadAction: {
                    ...state.downloadAction,
                    isLoading: false,
                    error: action.payload,
                },
            };
        default:
            return state;
    }
};
export default agreement;
