import { MenuAlt2Icon } from "@heroicons/react/outline";
import React,{useRef , useState , useEffect} from "react";
import { useDispatch , useSelector} from "react-redux";
import { Link, Redirect, useLocation } from "react-router-dom";
import { setModalStatus } from "../redux/common/actions";
import ProfileDropDown from "./ProfileDropdown";
import SideBar from "./Sidebar";
import {startCase} from "../utils/textstyle";
import { getCookie } from "../utils/cookies";
import * as URL from "../routes/url";



export default function MainLayout({ children }) {
  const dispatch = useDispatch();
  const mounted = useRef();
  const location = useLocation();
  const [isAdmin, setIsAdmin] = useState(false)

  const { isAuthenticated } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    console.log(location.pathname)
    if ((getCookie('ia') == 1 || getCookie('ia') == '1') && location.pathname != '/chat') {
      setIsAdmin(true)
    }
  }, [])
  
  return (
    <>
    <div className="h-screen bg-gray-50 overflow-auto">
      {children}
    </div>
      {isAdmin && <Link to={URL.URL_CHAT} className="chat_module"></Link>}
    </>
  );
}
