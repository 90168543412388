import React, {lazy, Suspense} from "react";
import {BrowserRouter, Switch} from "react-router-dom";
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";
import * as URL from "./url";


/** Lazy load components */
const Login = lazy(() => import("../pages/Login"));
const ResetPassword = lazy(() => import("../pages/ResetPassword"));
const UserLogin = lazy(() => import("../pages/UserLogin"));
const EntityList = lazy(() => import("../pages/Entity/EntityList"));
const Agreement = lazy(() => import("../pages/Clients/Agreement/AgreementListings"));
const CreateAgency = lazy(() => import("../pages/Agency/CreateAgency"));
const CreateAgreement = lazy(() => import("../pages/Clients/Agreement/CreateAgreement"));
const ReviewDocument = lazy(() => import("../pages/ReviewDocument"));
const SendDocument = lazy(() => import("../pages/SendDocument"));
const ListDashboard = lazy(() => import("../pages/ListDashboard"));
const UserList = lazy(() => import("../pages/UserList"));
const CreateUser = lazy(() => import("../pages/CreateUser"));
const EditUser = lazy(() => import("../pages/EditUser"));
const InvoiceList = lazy(() => import("../pages/InvoiceList"));
const ActionList = lazy(() => import("../pages/ActionList"));
const SpendList = lazy(() => import("../pages/SpendList"));
const UploadInvoice = lazy(() => import("../pages/UploadInvoice"));
const ClientList = lazy(() => import("../pages/ClientList"));
const UserPlan = lazy(() => import("../pages/UserPlan"));
const BillingBearkDown = lazy(() => import("../pages/BillingBearkDown"));
const BillingEdit = lazy(() => import("../pages/BillingEdit"));

const PhaseOption = lazy(() => import("../pages/phaseOption"));
const CsManager = lazy(() => import("../pages/CsManager"));
const Brands = lazy(() => import("../pages/Brands"));
const EditBrands = lazy(() => import("../pages/EditBrands"));
const Plan = lazy(() => import("../pages/Plan"));
const BrandsActivity = lazy(() => import("../pages/BrandsActivity"));
const BrandsAssignee = lazy(() => import("../pages/BrandsAssignee"));
const Chat = lazy(() => import("../pages/Chat"));
const PlanEdit = lazy(() => import("../pages/PlanEdit"));

const UserAdvancePlan = lazy(() => import("../pages/UserAdvancePlan"));
const InvoiceActivity = lazy(() => import("../pages/InvoiceActivity"));

const Phaselogs = lazy(() => import("../pages/Phaselogs"));
const EditClient = lazy(() => import("../pages/EditClient"));

const ResetUserPassword = lazy(() => import("../pages/ResetUserPassword"));

const UserLogs = lazy(() => import("../pages/UserLogs"));
const Reports = lazy(() => import("../pages/Reports"));
const Actions = lazy(() => import("../pages/Actions/index"));
const Spends = lazy(() => import("../pages/Spends/index"));

const RequestList = lazy(() => import("../pages/RequestList"));

const AgreementLogs = lazy(() => import("../pages/AgreementLogs"));


const routes = {
    private: [
        {
            path: "/",
            component: ListDashboard,
        },

        
        {
            path: `${URL.URL_REVIEW}/:id`,
            component: ReviewDocument,
        },
        {
            path: URL.URL_SEND,
            component: SendDocument,
        },
        {
            path: URL.URL_USERSLIST,
            component: UserList,
        },
        {
            path: URL.URL_CREATEUSER,
            component: CreateUser,
        },
        {
            path: `${URL.URL_EDITUSER}/:id`,
            component: EditUser,
        },
        {
            path: URL.URL_INVOICE,
            component: InvoiceList,
        },
        {
            path: URL.URL_UPLOADINVOICE,
            component: UploadInvoice,
        },
        {
            path: URL.URL_CLIENTS,
            component: ClientList,
        },
        {
            path: URL.URL_BILLING,
            component: BillingBearkDown,
        },
        {
            path: `${URL.URL_BILLING}/:id`,
            component: BillingEdit,
        },
        {
            path: `${URL.URL_PHASE}/:id/:id`,
            component: PhaseOption,
        },

        {
            path: URL.URL_MANAGER,
            component: CsManager,
        },

        {
            path: URL.URL_BRANDS,
            component: Brands,
        },

        {
            path: `${URL.URL_EDITBRANDS}/:id`,
            component: EditBrands,
        },

        {
            path: URL.URL_ADMIN_PLAN,
            component: Plan,
        },

        {
            path: URL.URL_BRANDS_LOGS,
            component: BrandsActivity,
        },

        {
            path: URL.URL_BRANDS_ASSIGNEE,
            component: BrandsAssignee,
        },

        {
            path: URL.URL_CHAT,
            component: Chat,
        },
        {
            path: `${URL.URL_EDIT_PLAN}/:id`,
            component: PlanEdit,
        },
        {
            path: URL.URL_PHASE_LOGS,
            component: Phaselogs,
        },

        {
            path: `${URL.URL_CLIENT_EDIT}/:id`,
            component: EditClient,
        },

        {
            path: URL.URL_USER_LOGS,
            component: UserLogs,
        },
        {
            path: URL.URL_REQUEST_LIST,
            component: RequestList,
        },
        {
            path: URL.URL_AGREEMENT_LOGS,
            component: AgreementLogs,
        },
    ],
    user: [
        {
            path: "/",
            component: ActionList,
        },
        {
            path: URL.URL_REPORT,
            component: ActionList,
        },
        {
            path: URL.URL_SPENDS,
            component: SpendList,
        },
        {
            path: URL.URL_INVOICE,
            component: InvoiceList,
        },
        {
            path: URL.URL_PLAN,
            component: UserPlan,
        },
        {
            path: URL.URL_ADVANCE_PLAN,
            component: UserAdvancePlan,
        },
        {
            path: URL.URL_INVOICES_ACTIVITY,
            component: InvoiceActivity,
        },
        {
            path: URL.URL_ADMIN_ACTIONS,
            component: Actions,
        },
        {
            path: URL.URL_ADMIN_SPENDS,
            component: Spends,
        },
    ],
    public: [
        {
            path: URL.URL_LOGIN,
            component: Login,
        },
        {
            path: URL.URL_RESETPASSWORD,
            component: ResetPassword,
        },
        {
            path: URL.URL_USERLOGIN,
            component: UserLogin,
        },
        {
            path: URL.URL_RESETUSERPASSWORD,
            component: ResetUserPassword,
        },
    ],
};
export default function URLRoutes() {
    return (
        <Suspense fallback={<div></div>}>
            <BrowserRouter>
                <Switch>
                    {routes.private.map((route, key) => {
                        const {component, path} = route;
                        return (
                            <PrivateRoute exact path={path} key={key} component={component}/>
                        );
                    })}
                    {routes.user.map((route, key) => {
                        const {component, path} = route;
                        return (
                            <PrivateRoute exact path={path} key={key} component={component}/>
                        );
                    })}
                    {routes.public.map((route, key) => {
                        const {component, path} = route;
                        return (
                            <PublicRoute exact path={path} key={key} component={component}/>
                        );
                    })}
                </Switch>
            </BrowserRouter>
        </Suspense>
    );
}
