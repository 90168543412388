import {
  CREATE_DOC,
  CREATE_DOC_SUCCESS,
  CREATE_DOC_FAILED,
  CREATE_DOC_RESET,
  UPLOAD_DOC,
  UPLOAD_DOC_SUCCESS,
  UPLOAD_DOC_FAILED,
} from "./types";

const initialState = {
  create: {
    isLoading: false,
    isCreated: false,
    data: [],
    error: null,
  },
  upload: {
    isLoading: false,
    isUploaded: false,
    filedata: [],
    error: null,
  },
};

const documentupload = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_DOC:
      return {
        ...state,
        create: {
          ...state.create,
          isLoading: true,
          error: null,
          isCreated: false,
        },
      };

    case CREATE_DOC_SUCCESS:
      return {
        ...state,
        create: {
          ...state.create,
          isLoading: false,
          isCreated: true,
          data: action.payload
        },
      };

    case CREATE_DOC_FAILED:
      return {
        ...state,
        create: {
          ...state.create,
          isLoading: false,
          error: action.payload,
        },
      };

    case CREATE_DOC_RESET:
      return { ...state, create: { ...initialState.create } };


    case UPLOAD_DOC:
      return {
        ...state,
        upload: {
          ...state.create,
          isLoading: true,
          isUploaded: false,
          error: null,
        },
      };

    case UPLOAD_DOC_SUCCESS:
      return {
        ...state,
        upload: {
          ...state.create,
          isLoading: false,
          isUploaded: true,
          filedata: action.payload,
          error: null
        },
      };

    case UPLOAD_DOC_FAILED:
      return {
        ...state,
        upload: {
          ...state.create,
          isLoading: false,
          isUploaded: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
};

export default documentupload;
