export const URL_LOGIN = "/login/admin";
export const URL_USERLOGIN = "/login";
export const URL_RESETPASSWORD = "/password";
export const URL_CLIENTS = "/clients";
export const URL_CLIENTS_CREATE = "/client/new";
export const URL_MEDIAPLAN = "/mediaplan";
export const URL_REPORT = "/actions";
export const URL_SPENDS = "/spends";
export const URL_HOME = "/";
export const URL_ENTITY = "/entity";
export const URL_AGENCY = "/agency";

export const URL_SEND = "/create";
export const URL_REVIEW = "/review";
export const URL_DASHBOARDLIST = "/dashboardlist";
export const URL_USERSLIST = "/users";
export const URL_CREATEUSER = "/create-users";
export const URL_EDITUSER = "/edit-users";
export const URL_INVOICE = "/invoice";
export const URL_UPLOADINVOICE = "/upload-invoice";
export const URL_PLAN = "/user-plan";
export const URL_BILLING = "/billing";
export const URL_PHASE = "/phase";

export const URL_MANAGER = "/manager-list";
export const URL_BRANDS = "/brands";
export const URL_EDITBRANDS = "/edit-brands";
export const URL_ADMIN_PLAN = "/plans";
export const URL_BRANDS_LOGS = "/brand-activity";
export const URL_BRANDS_ASSIGNEE = "/brand-assignee";
export const URL_CHAT = "/chat";
export const URL_EDIT_PLAN = "/edit-plan";

export const URL_ADVANCE_PLAN = "/advance-plan";
export const URL_INVOICES_ACTIVITY = "/invoices-activity";
export const URL_ADMIN_ACTIONS = "/admin-actions";
export const URL_ADMIN_SPENDS = "/admin-spends";
export const URL_PHASE_LOGS = "/phase-logs";
export const URL_CLIENT_EDIT = "/client-edit";

export const URL_RESETUSERPASSWORD = "/reset-password";
export const URL_USER_LOGS = "/user-logs";

export const URL_REQUEST_LIST = "/request";
export const URL_AGREEMENT_LOGS = "/agreement-logs";









