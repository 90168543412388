import { Dialog, Transition } from "@headlessui/react";
import {
  ChartBarIcon,
  CollectionIcon,
  CashIcon,
  InboxIcon,
  UsersIcon,
  XIcon,
} from "@heroicons/react/outline";
import React, { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import Logo from "../component/Logo";
import { setModalStatus } from "../redux/common/actions";
import * as URL from "../routes/url";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default withRouter(function SideBar({ history }) {
  const dispatch = useDispatch();
  const { sidebarOpen } = useSelector((state) => state.common.modalStatus);
  const navigation = [
    { name: "Clients", to: URL.URL_CLIENTS, icon: UsersIcon },
    { name: "Media Plan", to: URL.URL_MEDIAPLAN, icon: CollectionIcon },
    { name: "Invoice", to: URL.URL_INVOICE, icon: CashIcon },
    { name: "Reports", to: URL.URL_REPORT, icon: ChartBarIcon },
    { name: "Entity", to: URL.URL_ENTITY, icon: InboxIcon },
  ];

  return (
    <>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 flex z-40 lg:hidden"
          onClose={() => dispatch(setModalStatus({ sidebarOpen: false }))}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-primary">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    type="button"
                    className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() =>
                      dispatch(
                        setModalStatus({
                          sidebarOpen: false,
                        })
                      )
                    }
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </Transition.Child>
              <div className="flex-shrink-0 flex items-center px-4">
                <Logo className="h-8 w-auto" />
              </div>
              <div className="mt-5 flex-1 h-0 overflow-y-auto">
                <nav className="px-2 space-y-1">
                  {navigation.map((item) => (
                    <Link
                      key={item.name}
                      to={item.to}
                      className={classNames(
                        history.location.pathname === item.to
                          ? "bg-primary-dark text-white"
                          : "text-primary-light hover:bg-primary",
                        "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                      )}
                    >
                      <item.icon
                        className="mr-4 flex-shrink-0 h-6 w-6 text-primary-mild"
                        aria-hidden="true"
                      />
                      {item.name}
                    </Link>
                  ))}
                </nav>
              </div>
            </div>
          </Transition.Child>
          <div className="flex-shrink-0 w-14" aria-hidden="true">
            {/* Dummy element to force sidebar to shrink to fit close icon */}
          </div>
        </Dialog>
      </Transition.Root>
      <div className="hidden lg:flex lg:w-28 lg:flex-col lg:fixed lg:inset-y-0">
        <div className="flex flex-col flex-grow pt-5 bg-primary overflow-y-auto">
          <div className="flex items-center justify-center flex-shrink-0 px-4">
            <Logo className="h-8 w-auto" />
          </div>
          <div className="flex-1 mt-6 w-full px-2 space-y-1">
            {navigation.map((item) => (
              <Link
                key={item.name}
                to={item.to}
                className={classNames(
                  history.location.pathname === item.to
                    ? "bg-primary-dark text-white"
                    : "text-primary-verylight hover:bg-primary-dark hover:text-white",
                  "group w-full p-3 rounded-md flex flex-col items-center text-xs font-medium"
                )}
                aria-current={item.current ? "page" : undefined}
              >
                <item.icon
                  className={classNames(
                    item.current
                      ? "text-white"
                      : "text-primary-verylight group-hover:text-white",
                    "h-6 w-6"
                  )}
                  aria-hidden="true"
                />
                <span className="mt-2">{item.name}</span>
              </Link>
            ))}
          </div>
          {/* <div className="mt-5 flex-1 flex flex-col">
						<nav className="flex-1 px-2 pb-4 space-y-1">
							{navigation.map((item) => (
								<a
									key={item.name}
									href={item.href}
									className={classNames(
										item.current
											? "bg-indigo-800 text-white"
											: "text-indigo-100 hover:bg-indigo-600",
										"group flex items-center px-2 py-2 text-sm font-medium rounded-md"
									)}
								>
									<item.icon
										className="mr-3 flex-shrink-0 h-6 w-6 text-indigo-300"
										aria-hidden="true"
									/>
									{item.name}
								</a>
							))}
						</nav>
					</div> */}
        </div>
      </div>
    </>
  );
});
