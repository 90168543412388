import {eraseCookie} from "./cookies";

export const tokenKey = "token";

export const formatAxiosErrorMessage = (error) => {
    let err_msg = "";
    //console.log(error , error.message)

    if (error.response) {
        if (
            401 === error.response.status &&
            window.location.pathname !== "/login"
        ) {
            eraseCookie(tokenKey);
            window.location.replace("/login");
        }
        /*
         * The request was made and the server responded with a
         * status code that falls out of the range of 2xx
         */

        if (error && error.response && error.response.data && "detail" in error.response) {
            err_msg = error.response.data.detail;
        } else {
            for (var key in error.response.data) {
                err_msg += error.response.data[key];
            }
        }
    } else if (error.request) {
        /*
         * The request was made but no response was received, `error.request`
         */
        err_msg = "Something went wrong";
    } else {
        // Something happened in setting up the request and triggered an Error
        err_msg = error.message;
    }
    return err_msg;
};

// Date Format

export const generateQuery = (queryList) => {
    var finalQuery = '';
    queryList.forEach((item, index) => {
        if (index !== 0) {
            finalQuery += "&"
        }
        finalQuery += item.query + "=" + item.value
    })
    return finalQuery
}

export var dateFormat = "YYYY-MM-DD";
export var datePickerFormat = "yyyy-MM-dd";

export function dotStringToObject(obj, is, value) {
    if (typeof is == "string")
        return dotStringToObject(obj, is.split("."), value);
    else if (is.length == 1 && value !== undefined) return (obj[is[0]] = value);
    else if (is.length == 0) return obj;
    else return dotStringToObject(obj[is[0]], is.slice(1), value);
}
