export const GET_AGREEMENTS = "GET_AGREEMENTS";
export const GET_AGREEMENTS_SUCCESS = "GET_AGREEMENTS_SUCCESS";
export const GET_AGREEMENTS_FAILED = "GET_AGREEMENTS_FAILED";
export const GET_AGREEMENTS_RESET = "GET_AGREEMENTS_RESET";

export const GET_SPENDS = "GET_SPENDS";
export const GET_SPENDS_SUCCESS = "GET_SPENDS_SUCCESS";
export const GET_SPENDS_FAILED = "GET_SPENDS_FAILED";
export const GET_SPENDS_RESET = "GET_SPENDS_RESET";

export const GET_DAILY_SPENDS = "GET_DAILY_SPENDS";
export const GET_DAILY_SPENDS_SUCCESS = "GET_DAILY_SPENDS_SUCCESS";
export const GET_DAILY_SPENDS_FAILED = "GET_DAILY_SPENDS_FAILED";
export const GET_DAILY_SPENDS_RESET = "GET_DAILY_SPENDS_RESET";

export const GET_MONTH = "GET_MONTH";
export const GET_MONTH_SUCCESS = "GET_MONTH_SUCCESS";
export const GET_MONTH_FAILED = "GET_MONTH_FAILED";

export const GET_CLIENTLIST = "GET_CLIENTLIST";
export const GET_CLIENTLIST_SUCCESS = "GET_CLIENTLIST_SUCCESS";
export const GET_CLIENTLIST_FAILED = "GET_CLIENTLIST_FAILED";

export const GET_CLIENTEXPORT = "GET_CLIENTEXPORT";
export const GET_CLIENTEXPORT_SUCCESS = "GET_CLIENTEXPORT_SUCCESS";
export const GET_CLIENTEXPORT_FAILED = "GET_CLIENTEXPORT_FAILED";

export const GET_BILLING_SPENT = "GET_BILLING_SPENT";
export const GET_BILLING_SPENT_FAILED = "GET_BILLING_SPENT_FAILED";
export const GET_BILLING_SPENT_SUCCESS = "GET_BILLING_SPENT_SUCCESS";

export const UPDATE_SPENT_STATUS = "UPDATE_SPENT_STATUS";
export const UPDATE_SPENT_STATUS_FAILED = "UPDATE_SPENT_STATUS_FAILED";
export const UPDATE_SPENT_STATUS_SUCCESS = "UPDATE_SPENT_STATUS_SUCCESS";
export const UPDATE_SPENT_STATUS_RESET = "UPDATE_SPENT_STATUS_RESET";

export const DOWNLOAD_SPENT_STATUS = "DOWNLOAD_SPENT_STATUS";
export const DOWNLOAD_SPENT_STATUS_FAILED = "DOWNLOAD_SPENT_STATUS_FAILED";
export const DOWNLOAD_SPENT_STATUS_SUCCESS = "DOWNLOAD_SPENT_STATUS_SUCCESS";

export const DOWNLOAD_ACTION_STATUS = "DOWNLOAD_ACTION_STATUS";
export const DOWNLOAD_ACTION_STATUS_FAILED = "DOWNLOAD_ACTION_STATUS_FAILED";
export const DOWNLOAD_ACTION_STATUS_SUCCESS = "DOWNLOAD_ACTION_STATUS_SUCCESS";

