export const GET_INVOICE_LIST = "GET_INVOICE_LIST";
export const GET_INVOICE_SUCCESS = "GET_INVOICE_SUCCESS";
export const GET_INVOICE_FAILED = "GET_INVOICE_FAILED";

export const SET_UPLOAD_INVOICE = "SET_UPLOAD_INVOICE";
export const SET_UPLOAD_INVOICE_SUCCESS = "SET_UPLOAD_INVOICE_SUCCESS";
export const SET_UPLOAD_INVOICE_FAILED = "SET_UPLOAD_INVOICE_FAILED";

export const GET_USERPLAN = "GET_USERPLAN";
export const GET_USERPLAN_SUCCESS = "GET_USERPLAN_SUCCESS";
export const GET_USERPLAN_FAILED = "GET_USERPLAN_FAILED";

export const SET_APPROVE_PLAN = "SET_APPROVE_PLAN";
export const SET_APPROVE_PLAN_SUCCESS = "SET_APPROVE_PLAN_SUCCESS";
export const SET_APPROVE_PLAN_FAILED = "SET_APPROVE_PLAN_FAILED";
export const SET_APPROVE_PLAN_RESET = "SET_APPROVE_PLAN_RESET";

export const SET_APPROVE_REJECT_PLAN = "SET_APPROVE_REJECT_PLAN";
export const SET_APPROVE_REJECT_PLAN_SUCCESS = "SET_APPROVE_REJECT_PLAN_SUCCESS";
export const SET_APPROVE_REJECT_PLAN_FAILED = "SET_APPROVE_REJECT_PLAN_FAILED";

export const SET_ADVANCE_PLAN = "SET_ADVANCE_PLAN";
export const SET_ADVANCE_PLAN_SUCCESS = "SET_ADVANCE_PLAN_SUCCESS";
export const SET_ADVANCE_PLAN_FAILED = "SET_ADVANCE_PLAN_FAILED";
export const SET_ADVANCE_PLAN_RESET = "SET_ADVANCE_PLAN_RESET";

export const GET_ADVANCE_PLAN = "GET_ADVANCE_PLAN";
export const GET_ADVANCE_PLAN_SUCCESS = "GET_ADVANCE_PLAN_SUCCESS";
export const GET_ADVANCE_PLAN_FAILED = "GET_ADVANCE_PLAN_FAILED";

export const APPROVE_INVOICE = "APPROVE_INVOICE";
export const APPROVE_INVOICE_SUCCESS = "APPROVE_INVOICE_SUCCESS";
export const APPROVE_INVOICE_FAILED = "APPROVE_INVOICE_FAILED";
export const APPROVE_INVOICE_RESET = "APPROVE_INVOICE_RESET";

export const REJECT_INVOICE = "REJECT_INVOICE";
export const REJECT_INVOICE_SUCCESS = "REJECT_INVOICE_SUCCESS";
export const REJECT_INVOICE_FAILED = "REJECT_INVOICE_FAILED";
export const REJECT_INVOICE_RESET = "REJECT_INVOICE_RESET";

export const GET_INVOICE_LOGS = "GET_INVOICE_LOGS";
export const GET_INVOICE_LOGS_SUCCESS = "GET_INVOICE_LOGS_SUCCESS";
export const GET_INVOICE_LOGS_FAILED = "GET_INVOICE_LOGS_FAILED";


