import firebase from 'firebase'

const config = {
    apiKey: "AIzaSyD2OEwTWkTGqsKh5KnvUWe5XhKJ_2cie_k",
    authDomain: "chat-dafb7.firebaseapp.com",
    databaseURL: "https://chat-dafb7-default-rtdb.firebaseio.com",
    projectId: "chat-dafb7",
    storageBucket: "chat-dafb7.appspot.com",
    messagingSenderId: "155381721324",
    appId: "1:155381721324:web:86d31ea4803b7ffa25ddc9",
    measurementId: "G-CHLQCQ9P6F"
}
firebase.initializeApp(config)
firebase.firestore().settings({
    timestampsInSnapshots: true
})

export const myFirebase = firebase
export const myFirestore = firebase.firestore()
export const myStorage = firebase.storage()
