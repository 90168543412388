import { Menu, Transition } from "@headlessui/react";
import { useDispatch, useSelector } from "react-redux";
import React, { Fragment, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import iconArrow from "../assets/images/public/icon-arrow.svg";
import { tokenKey } from "../utils/common";
import { eraseCookie } from "../utils/cookies";
import {myFirebase, myFirestore} from '../Config/MyFirebase'
import * as URL from "../routes/url";
import { LOGIN_RESET } from "../redux/auth/types";
import {
  setModalStatus,
  reset,
} from "../redux/common/actions";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ProfileDropDown() {
  const dispatch = useDispatch();
  const [User, setUser] = useState();

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem('user')));

  }, [])
  

  const userNavigation = [
    { name: "Logout", href: URL.URL_CLIENTS},
  ];

  const doLogout = () => {
    myFirebase
        .auth()
        .signOut()
        .then(() => {
              localStorage.clear()
          })
        .catch(function (err) {
            console(0, err.message)
        })
  }

  const history = useHistory();
  const logoutUser = () => {
    doLogout()
    eraseCookie(tokenKey);
    eraseCookie('account');
    eraseCookie('ia');
    eraseCookie("role");
    eraseCookie("pic");  
    localStorage.clear()
    setTimeout(() => {
      history.push(URL.URL_USERLOGIN);  
    }, 500);
    return () => dispatch(reset(LOGIN_RESET));
  };

  return (
    <Menu as="div" className="ml-3 relative">
      <div>
        <Menu.Button className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none ">
          <span className="sr-only">Open user menu</span>
          <span className="inline-flex items-center justify-center h-8 w-8 rounded-full bg-gray-500">
            <span className="text-sm font-medium leading-none text-white">
              {User?.firstname.charAt(0).toUpperCase()}
            </span>
          </span>
          <span className="ml-1">
            <img src={iconArrow} alt="Pixis" />
          </span>
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
          {userNavigation.map((item) => (
						<Menu.Item key={item.name}>
							{({ active }) => (
								<a
                  onClick={logoutUser}
									className={classNames(
										active ? "bg-gray-100" : "",
										"block px-4 py-2 text-sm text-gray-700"
									)}
								>
									{item.name}
								</a>
							)}
						</Menu.Item>
					))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
