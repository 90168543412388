import { eraseCookie, setCookie } from "../../utils/cookies";
import {
  LOGIN_FAILED,
  LOGIN_REQUEST,
  LOGIN_RESET,
  LOGIN_SUCCESS,

  RESET_PASSWORD, 
  RESET_PASSWORD_SUCCESS, 
  RESET_PASSWORD_FAILED,
  RESET_PASSWORD_RESET,

  GET_OTP_PASSWORD, 
  GET_OTP_PASSWORD_SUCCESS, 
  GET_OTP_PASSWORD_FAILED,
  GET_OTP_PASSWORD_RESET,

  RESET_USER_PASSWORD, 
  RESET_USER_PASSWORD_SUCCESS, 
  RESET_USER_PASSWORD_FAILED,
  RESET_USER_PASSWORD_RESET,
} from "./types";

const initialState = {
  isAuthenticated: false,
  isLoading: false,
  isError: false,
  data: [],
  loginstate : {
    isAuthenticated: false,
    isLoading: false,
    isError: false,
    data: [],
  },
  resetpass: {    
    isLoading: false,
    data: [],
    error: null,
    isError: false,
    isChanged : false
  },

  resetuserOtp: {    
    isLoading: false,
    data: [],
    error: null,
    isError: false,
    isChanged : false
  },
  resetuserpass: {    
    isLoading: false,
    data: [],
    error: null,
    isError: false,
    isChanged : false
  },
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_RESET:
      return initialState;
    case LOGIN_REQUEST:
      return {
        ...state,
        loginstate: {
          ...state.loginstate,
          isAuthenticated: false,
          isLoading: true,
          isError: false,
        }
      };
    case LOGIN_SUCCESS:          
    console.log(action.payload.data.role)
      
      var setIsAdmin = action.payload.data.role == "user" ? 0 : 1;

      setCookie("token", action.payload.data.api_token,1);
      if(action.payload.data.using_default_password != 'Yes'){
        setCookie("login", action.payload.data.admin_id,1);  
        setCookie("ia", JSON.stringify(setIsAdmin),1); 
        setCookie("role", action.payload.data.role, 1);  
        setCookie("account", JSON.stringify(action.payload.data.userAccounts),1);
        localStorage.setItem('user', JSON.stringify(action.payload.data));
      }else{
        setCookie("pic", action.payload.data.using_default_password,1);  
      }


      return {
        ...state,
        loginstate: {
          ...state.loginstate,
          data: action.payload.data,
          isAuthenticated: true,
          isLoading: false,
        }
      };
    case LOGIN_FAILED:
      eraseCookie("token");
      eraseCookie("login");
      eraseCookie("ia");
      eraseCookie("role");
      eraseCookie("pic");  
      localStorage.clear()
      return {
        ...state,
        loginstate: {
          ...state.loginstate,
          isAuthenticated: false,
          isLoading: false,
          isError: action.payload,
        }
      };
     
      case RESET_PASSWORD_RESET:
        return initialState;
      case RESET_PASSWORD:
        return {
          ...state,
          resetpass: {
            ...state.resetpass,
            isLoading: false,
            data: action.payload,
            isError: false,
            isChanged : false
          },
        };
      case RESET_PASSWORD_SUCCESS:          
        eraseCookie("pic"); 
        eraseCookie("token");
        return {
          ...state,
          resetpass: {
            ...state.resetpass,
            isLoading: false,
            data: action.payload,
            isError: false,
            isChanged : true,
          },
        };
  
      case RESET_PASSWORD_FAILED:
        return {
          ...state,
          resetpass: {
            ...state.resetpass,
            isLoading: false,
            isError: action.payload,
            isChanged : false
          },
        };
        
      case GET_OTP_PASSWORD_RESET:
        return initialState.resetuserOtp;
      case GET_OTP_PASSWORD:
        return {
          ...state,
          resetuserOtp: {
            ...state.resetuserOtp,
            isLoading: false,
            data: action.payload,
            isError: false,
            isChanged : false
          },
        };
      case GET_OTP_PASSWORD_SUCCESS:          
        eraseCookie("pic"); 
        eraseCookie("token");
        return {
          ...state,
          resetuserOtp: {
            ...state.resetuserOtp,
            isLoading: false,
            data: action.payload,
            isError: false,
            isChanged : true,
          },
        };
  
      case GET_OTP_PASSWORD_FAILED:
        return {
          ...state,
          resetuserOtp: {
            ...state.resetuserOtp,
            isLoading: false,
            isError: action.payload,
            isChanged : false
          },
        };
    
      case RESET_USER_PASSWORD_RESET:
        return initialState.resetuserpass;
      case RESET_USER_PASSWORD:
        return {
          ...state,
          resetuserpass: {
            ...state.resetuserpass,
            isLoading: false,
            data: action.payload,
            isError: false,
            isChanged : false
          },
        };
      case RESET_USER_PASSWORD_SUCCESS:          
    
        return {
          ...state,
          resetuserpass: {
            ...state.resetuserpass,
            isLoading: false,
            data: action.payload,
            isError: false,
            isChanged : true,
          },
        };
  
      case RESET_USER_PASSWORD_FAILED:
        return {
          ...state,
          resetuserpass: {
            ...state.resetuserpass,
            isLoading: false,
            isError: action.payload,
            isChanged : false
          },
        };
    
    
    default:
      return state;
  }
};
export default auth;
