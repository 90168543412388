export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGIN_RESET = "LOGIN_RESET";


export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILED = "RESET_PASSWORD_FAILED";
export const RESET_PASSWORD_RESET = "RESET_PASSWORD_RESET";

export const GET_OTP_PASSWORD = "GET_OTP_PASSWORD";
export const GET_OTP_PASSWORD_SUCCESS = "GET_OTP_PASSWORD_SUCCESS";
export const GET_OTP_PASSWORD_FAILED = "GET_OTP_PASSWORD_FAILED";
export const GET_OTP_PASSWORD_RESET = "GET_OTP_PASSWORD_RESET";

export const RESET_USER_PASSWORD = "RESET_USER_PASSWORD";
export const RESET_USER_PASSWORD_SUCCESS = "RESET_USER_PASSWORD_SUCCESS";
export const RESET_USER_PASSWORD_FAILED = "RESET_USER_PASSWORD_FAILED";
export const RESET_USER_PASSWORD_RESET = "RESET_USER_PASSWORD_RESET";
