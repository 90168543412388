export const CREATE_USER = "CREATE_USER";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const CREATE_USER_FAILED = "CREATE_USER_FAILED";
export const CREATE_USER_RESET = "CREATE_USER_RESET";

export const GET_ACCOUNT = "GET_ACCOUNT";
export const GET_ACCOUNT_SUCCESS = "GET_ACCOUNT_SUCCESS";
export const GET_ACCOUNT_FAILED = "GET_ACCOUNT_FAILED";

export const GET_USER_LIST = "GET_USER_LIST";
export const GET_USER_LIST_SUCCESS = "GET_USER_LIST_SUCCESS";
export const GET_USER_LIST_FAILED = "GET_USER_LIST_FAILED";

export const GET_USER = "GET_USER";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAILED = "GET_USER_FAILED";

export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILED = "UPDATE_USER_FAILED";
export const UPDATE_USER_RESET = "UPDATE_USER_RESET";

export const DELETE_USER = "DELETE_USER";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAILED = "DELETE_USER_FAILED";
export const DELETE_USER_RESET  = "DELETE_USER_RESET";

export const BRAND_USER = "BRAND_USER";
export const BRAND_USER_SUCCESS = "BRAND_USER_SUCCESS";
export const BRAND_USER_FAILED = "BRAND_USER_FAILED";
export const BRAND_USER_RESET  = "BRAND_USER_RESET";

export const UPDATE_BRAND_USER = "UPDATE_BRAND_USER";
export const UPDATE_BRAND_USER_SUCCESS = "UPDATE_BRAND_USER_SUCCESS";
export const UPDATE_BRAND_USER_FAILED = "UPDATE_BRAND_USER_FAILED";
export const UPDATE_BRAND_USER_RESET  = "UPDATE_BRAND_USER_RESET";

export const GET_BRANDS_LOG = "GET_BRANDS_LOG";
export const GET_BRANDS_LOG_SUCCESS = "GET_BRANDS_LOG_SUCCESS";
export const GET_BRANDS_LOG_FAILED = "GET_BRANDS_LOG_FAILED";

export const GET_EXECUTIVE_LOG = "GET_EXECUTIVE_LOG";
export const GET_EXECUTIVE_LOG_SUCCESS = "GET_EXECUTIVE_LOG_SUCCESS";
export const GET_EXECUTIVE_LOG_FAILED = "GET_EXECUTIVE_LOG_FAILED";

export const GET_PHASE_LOG = "GET_PHASE_LOG";
export const GET_PHASE_LOG_SUCCESS = "GET_PHASE_LOG_SUCCESS";
export const GET_PHASE_LOG_FAILED = "GET_PHASE_LOG_FAILED";

export const GET_CLIENT_DETAILS = "GET_CLIENT_DETAILS";
export const GET_CLIENT_DETAILS_SUCCESS = "GET_CLIENT_DETAILS_SUCCESS";
export const GET_CLIENT_DETAILS_FAILED = "GET_CLIENT_DETAILS_FAILED";

export const UPDATE_CLIENT_DETAILS = "UPDATE_CLIENT_DETAILS";
export const UPDATE_CLIENT_DETAILS_SUCCESS = "UPDATE_CLIENT_DETAILS_SUCCESS";
export const UPDATE_CLIENT_DETAILS_FAILED = "UPDATE_CLIENT_DETAILS_FAILED";
export const UPDATE_CLIENT_DETAILS_RESET = "UPDATE_CLIENT_DETAILS_RESET";

export const GET_USERS_LOGS = "GET_USERS_LOGS";
export const GET_USERS_LOGS_SUCCESS = "GET_USERS_LOGS_SUCCESS";
export const GET_USERS_LOGS_FAILED = "GET_USERS_LOGS_FAILED";
