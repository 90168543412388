import {
  GET_DOC_REVIEW,
  GET_DOC_REVIEW_SUCCESS,
  GET_DOC_REVIEW_FAILED,
  GET_DOC_REVIEW_RESET
} from "./types";

const initialState = {
  review: {
    isLoading: false,
    data: [],
    error: null,
  },
};

const review = (state = initialState, action) => {
  switch (action.type) {
    case GET_DOC_REVIEW:
      return {
        ...state,
        review: {
          ...state.review,
          isLoading: true,
          error: null,
          isCreated: false,
        },
      };

    case GET_DOC_REVIEW_SUCCESS:
      return {
        ...state,
        review: {
          ...state.review,
          isLoading: false,
          error: null,
          data : action.payload
        },
      };

    case GET_DOC_REVIEW_FAILED:
      return {
        ...state,
        review: {
          ...state.review,
          isLoading: false,
          error: action.payload,
        },
      };

    case GET_DOC_REVIEW_RESET:
      return { ...state, create: { ...initialState.create } };

    default:
      return state;
  }
};

export default review;
