import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import MainLayout from "../layout/MainLayout";
import { URL_LOGIN, URL_USERLOGIN } from "./url";
/**
 *
 * @param path
 * @returns {JSX.Element}
 */
export const returnToRoute = (path) => {
  return <Redirect to={path} />;
};

/**
 *
 * @param Component
 * @param rest
 * @returns {JSX.Element}
 * @constructor
 */
const PrivateRoute = ({ component: Component, ...rest }) => {
  const auth = useSelector((state) => state.auth);
  const { isAuthenticated } = auth;

  if (false && !isAuthenticated) {
  //  return <Route {...rest} render={(props) => returnToRoute(URL_USERLOGIN)} />;
  } else {
    return (
      <Route
        {...rest}
        render={(props) => (
          <MainLayout>
            <Component {...props} />
          </MainLayout>
        )}
      />
    );
  }
};

export default PrivateRoute;
