import {
  CREATE_USER,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILED,
  CREATE_USER_RESET,
  GET_ACCOUNT,
  GET_ACCOUNT_SUCCESS,
  GET_ACCOUNT_FAILED ,
  GET_USER_LIST,
  GET_USER_LIST_SUCCESS,
  GET_USER_LIST_FAILED ,

  GET_USER,
  GET_USER_SUCCESS,
  GET_USER_FAILED ,

  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILED ,
  UPDATE_USER_RESET,

  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILED ,
  DELETE_USER_RESET,

  BRAND_USER,
  BRAND_USER_SUCCESS,
  BRAND_USER_FAILED ,

  UPDATE_BRAND_USER,
  UPDATE_BRAND_USER_SUCCESS,
  UPDATE_BRAND_USER_FAILED,
  UPDATE_BRAND_USER_RESET,

  GET_BRANDS_LOG,
  GET_BRANDS_LOG_SUCCESS,
  GET_BRANDS_LOG_FAILED,

  GET_EXECUTIVE_LOG,
  GET_EXECUTIVE_LOG_SUCCESS,
  GET_EXECUTIVE_LOG_FAILED,

  GET_PHASE_LOG,
  GET_PHASE_LOG_SUCCESS,
  GET_PHASE_LOG_FAILED,

  GET_CLIENT_DETAILS,
  GET_CLIENT_DETAILS_SUCCESS,
  GET_CLIENT_DETAILS_FAILED,

  UPDATE_CLIENT_DETAILS,
  UPDATE_CLIENT_DETAILS_SUCCESS,
  UPDATE_CLIENT_DETAILS_FAILED,
  UPDATE_CLIENT_DETAILS_RESET,

  GET_USERS_LOGS,
  GET_USERS_LOGS_SUCCESS,
  GET_USERS_LOGS_FAILED
} from "./types";

const initialState = {
  create: {
    isLoading: false,
    isCreated: false,
    data: [],
    error: null,
  },
  account: {
    isLoading: false,
    isCreated: false,
    data: [],
    error: null,
  },
  users: {
    isLoading: false,    
    data: [],
    error: null,
  },

  user: {
    isLoading: false,    
    data: [],
    error: null,
  },

  update: {
    isLoading: false,
    isUpdated: false,
    data: [],
    error: null,
  },

  deleteuser: {
    isLoading: false,
    isDeleted: false,
    data: [],
    error: null,
  },

  branduser: {
    isLoading: false,
    isDeleted: false,
    data: [],
    error: null,
  },

  updatebranduser: {
    isLoading: false,
    isUpdated: false,
    data: [],
    error: null,
  },

  brandlogs: {
    isLoading: false,
    isUpdated: false,
    data: [],
    error: null,
  },

  executivelogs: {
    isLoading: false,
    isUpdated: false,
    data: [],
    error: null,
  },

  phaselogs: {
    isLoading: false,
    isUpdated: false,
    data: [],
    error: null,
  },

  detialClient: {
    isLoading: false,
    isUpdated: false,
    data: [],
    error: null,
  },

  updateClient: {
    isLoading: false,
    isUpdated: false,
    data: [],
    error: null,
  },

  usersLogs: {
    isLoading: false,
    isUpdated: false,
    data: [],
    error: null,
  },
};

const usercreate = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_USER:
      return {
        ...state,
        create: {
          ...state.create,
          isLoading: true,
          error: null,
          isCreated: false,
        },
      };
    case CREATE_USER_SUCCESS:
      return {
        ...state,
        create: {
          ...state.create,
          isLoading: false,
          isCreated: true,
          error: null,
          data : action.payload
        },
      };
    case CREATE_USER_FAILED:
      return {
        ...state,
        create: {
          ...state.create,
          isLoading: false,
          isCreated: false,
          error: action.payload,
        },
      };
    case CREATE_USER_RESET:
      return { ...state, create: { ...initialState.create } };
    case GET_ACCOUNT:
      return {
        ...state,
        account: {
          ...state.create,
          isLoading: true,
          error: null,
        },
    };
    case GET_ACCOUNT_SUCCESS:
      return {
        ...state,
        account: {
          data: action.payload,
        },
      };
    case GET_ACCOUNT_FAILED:
      return {
        ...state,
        account: {
          ...state.create,
          isLoading: false,
          error: action.payload,
        },
      };
    case GET_USER_LIST:
      return {
      ...state,
        users: {
          ...state.create,
          isLoading: true,
          error: null,
        },
    };
    case GET_USER_LIST_SUCCESS:
      return {
        ...state,
        users: {
          isLoading: false,
          data: action.payload,
        },
      };
    case GET_USER_LIST_FAILED:
      return {
        ...state,
        users: {
          ...state.create,
          isLoading: false,
          error: action.payload,
        },
      };
    case GET_USER:
      return {
      ...state,
        user: {
          ...state.user,
          isLoading: true,
          error: null,
        },
    };
    case GET_USER_SUCCESS:
      return {
        ...state,
        user: {
          isLoading: false,
          data: action.payload,
        },
      };
    case GET_USER_FAILED:
      return {
        ...state,
        user: {
          ...state.user,
          isLoading: false,
          error: action.payload,
        },
      };   
    case UPDATE_USER:
      return {
        ...state,
        update: {
          ...state.create,
          isLoading: true,
          error: null,
          isCreated: false,
        },
      };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        update: {
          ...state.create,
          isLoading: false,
          isUpdated: true,
          error: null,
          data : action.payload
        },
      };
    case UPDATE_USER_FAILED:
      return {
        ...state,
        update: {
          ...state.create,
          isLoading: false,
          isUpdated: false,
          error: action.payload,
        },
      };
    case UPDATE_USER_RESET:
      return { ...state, update: { ...initialState.update } };
    case DELETE_USER:
      return {
        ...state,
        deleteuser: {
          ...state.create,
          isLoading: true,
          error: null,
          isCreated: false,
        },
      };
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        deleteuser: {
          ...state.create,
          isLoading: false,
          isDeleted: true,
          error: null,
          data : action.payload
        },
      };
    case DELETE_USER_FAILED:
      return {
        ...state,
        deleteuser: {
          ...state.create,
          isLoading: false,
          isDeleted: false,
          error: action.payload,
        },
      };
    case DELETE_USER_RESET:
        return { ...state, deleteuser: { ...initialState.deleteuser } };
    case BRAND_USER:
      return {
        ...state,
        branduser: {
          ...state.branduser,
          isLoading: true,
          error: null,
        },
      };
    case BRAND_USER_SUCCESS:
      return {
        ...state,
        branduser: {
          ...state.branduser,
          isLoading: false,
          isDeleted: true,
          error: null,
          data : action.payload
        },
      };
    case BRAND_USER_FAILED:
      return {
        ...state,
        branduser: {
          ...state.branduser,
          isLoading: false,
          isDeleted: false,
          error: action.payload,
        },
      };
    case UPDATE_BRAND_USER:
      return {
        ...state,
        updatebranduser: {
          ...state.updatebranduser,
          isLoading: true,
          error: null,
        },
      };
    case UPDATE_BRAND_USER_SUCCESS:
      return {
        ...state,
        updatebranduser: {
          ...state.updatebranduser,
          isLoading: false,
          isUpdated: true,
          error: null,
          data : action.payload
        },
      };
    case UPDATE_BRAND_USER_FAILED:
      return {
        ...state,
        updatebranduser: {
          ...state.updatebranduser,
          isLoading: false,
          isUpdated: false,
          error: action.payload,
        },
      };
    case UPDATE_BRAND_USER_RESET:
      return { ...state, updatebranduser: { ...initialState.updatebranduser } };
    case GET_BRANDS_LOG:
      return {
        ...state,
        brandlogs: {
          ...state.brandlogs,
          isLoading: true,
          error: null,
        },
      };
    case GET_BRANDS_LOG_SUCCESS:
      return {
        ...state,
        brandlogs: {
          ...state.brandlogs,
          isLoading: false,
          isUpdated: true,
          error: null,
          data : action.payload
        },
      };
    case GET_BRANDS_LOG_FAILED:
      return {
        ...state,
        brandlogs: {
          ...state.brandlogs,
          isLoading: false,
          isUpdated: false,
          error: action.payload,
        },
      };
    

    case GET_EXECUTIVE_LOG:
      return {
        ...state,
        executivelogs: {
          ...state.executivelogs,
          isLoading: true,
          error: null,
        },
      };
    case GET_EXECUTIVE_LOG_SUCCESS:
      return {
        ...state,
        executivelogs: {
          ...state.executivelogs,
          isLoading: false,
          isUpdated: true,
          error: null,
          data : action.payload
        },
      };
    case GET_EXECUTIVE_LOG_FAILED:
      return {
        ...state,
        executivelogs: {
          ...state.executivelogs,
          isLoading: false,
          isUpdated: false,
          error: action.payload,
        },
      };
    

    case GET_PHASE_LOG:
      return {
        ...state,
        phaselogs: {
          ...state.phaselogs,
          isLoading: true,
          error: null,
        },
      };
    case GET_PHASE_LOG_SUCCESS:
      return {
        ...state,
        phaselogs: {
          ...state.phaselogs,
          isLoading: false,
          isUpdated: true,
          error: null,
          data : action.payload
        },
      };
    case GET_PHASE_LOG_FAILED:
      return {
        ...state,
        phaselogs: {
          ...state.phaselogs,
          isLoading: false,
          isUpdated: false,
          error: action.payload,
        },
      };
    

    case GET_CLIENT_DETAILS:
      return {
        ...state,
        detialClient: {
          ...state.detialClient,
          isLoading: true,
          error: null,
        },
      };
    case GET_CLIENT_DETAILS_SUCCESS:
      return {
        ...state,
        detialClient: {
          ...state.detialClient,
          isLoading: false,
          isUpdated: true,
          error: null,
          data : action.payload
        },
      };
    case GET_CLIENT_DETAILS_FAILED:
      return {
        ...state,
        detialClient: {
          ...state.detialClient,
          isLoading: false,
          isUpdated: false,
          error: action.payload,
        },
      };
    
    case UPDATE_CLIENT_DETAILS:
      return {
        ...state,
        updateClient: {
          ...state.updateClient,
          isLoading: true,
          error: null,
        },
      };
    case UPDATE_CLIENT_DETAILS_SUCCESS:
      return {
        ...state,
        updateClient: {
          ...state.updateClient,
          isLoading: false,
          isUpdated: true,
          error: null,
          data : action.payload
        },
      };
    case UPDATE_CLIENT_DETAILS_FAILED:
      return {
        ...state,
        updateClient: {
          ...state.updateClient,
          isLoading: false,
          isUpdated: false,
          error: action.payload,
        },
      };
    case UPDATE_CLIENT_DETAILS_RESET:
      return { ...state, updateClient: { ...initialState.updateClient } };
        
    case GET_USERS_LOGS:
      return {
        ...state,
        usersLogs: {
          ...state.usersLogs,
          isLoading: true,
          error: null,
        },
      };
    case GET_USERS_LOGS_SUCCESS:
      return {
        ...state,
        usersLogs: {
          ...state.usersLogs,
          isLoading: false,
          isUpdated: true,
          error: null,
          data : action.payload
        },
      };
    case GET_USERS_LOGS_FAILED:
      return {
        ...state,
        usersLogs: {
          ...state.usersLogs,
          isLoading: false,
          isUpdated: false,
          error: action.payload,
        },
      };
      
    default:
      return state;
  }
};

export default usercreate;
