import { combineReducers } from "redux";
import agency from "./agency/reducers";
import auth from "./auth/reducers";
import client from "./client";
import common from "./common/reducers";
import entity from "./entity/reducers";
import usercreate from "./createuser/reducers";
import documentupload from "./createdoc/reducers";
import aggreementlist from "./aggreementlist/reducers";
import review from "./review/reducers";
import invoicelist from "./invoice/reducers";

export default combineReducers({
  auth,
  agency,
  client,
  common,
  entity,
  usercreate,
  documentupload,
  aggreementlist,
  review,
  invoicelist
});
