import React from "react";
import { Route } from "react-router-dom";
import PublicLayout from "../layout/PublicLayout";

/**
 *
 * @param Component
 * @param rest
 * @returns {JSX.Element}
 */
export default function PublicRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => (
        <PublicLayout>
          <Component {...props} />
        </PublicLayout>
      )}
    />
  );
}
