import {
  GET_ENTITIES,
  GET_ENTITIES_ERROR,
  GET_ENTITIES_SUCCESS,
  GET_ENTITY_DETAILS,
  GET_ENTITY_DETAILS_ERROR,
  GET_ENTITY_DETAILS_RESET,
  GET_ENTITY_DETAILS_SUCCESS
} from "./types";

const initialState = {
  get: {
    isLoading: false,
    data: [],
    error: null,
  },
  retrieve: {
    isLoading: false,
    data: null,
    error: null,
  },
};

const entity = (state = initialState, action) => {
  switch (action.type) {
    case GET_ENTITIES:
      return {
        ...state,
        get: {
          ...state.get,
          isLoading: true,
          error: null,
        },
      };
    case GET_ENTITIES_SUCCESS:
      return {
        ...state,
        get: {
          ...state.get,
          isLoading: false,
          data: action.payload,
        },
      };
    case GET_ENTITIES_ERROR:
      return {
        ...state,
        get: {
          ...state.get,
          isLoading: false,
          error: action.payload,
        },
      };
    case GET_ENTITY_DETAILS_RESET:
      return {
        ...state,
        retrieve: initialState.retrieve,
      };
    case GET_ENTITY_DETAILS:
      return {
        ...state,
        retrieve: {
          ...state.retrieve,
          isLoading: true,
          data:null,
          error: null,
        },
      };
    case GET_ENTITY_DETAILS_SUCCESS:
      return {
        ...state,
        retrieve: {
          ...state.retrieve,
          isLoading: false,
          data: action.payload,
        },
      };
    case GET_ENTITY_DETAILS_ERROR:
      return {
        ...state,
        retrieve: {
          ...state.retrieve,
          isLoading: false,
          error: action.payload,
        },
      };

    default:
      return state;
  }
};
export default entity;
