import { SET_MODAL_STATUS } from "./types";

const initialState = {
  modalStatus: {
    sidebarOpen: false,
    showEntity:false,
  },
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case SET_MODAL_STATUS:
      return {
        ...state,
        modalStatus: {
          ...state.modalStatus,
          ...action.payload,
        },
      };

    default:
      return state;
  }
};
export default auth;
